const nav = [
    {
      text: "Home",
      link: "/",
      isClicked: true,
    },
    {
      text: "Kurral",
      link: "/kurral",
      isClicked: false,
    },
    {
      text: "Excercise Kurral",
      link: "/kurral/excercise",
      isClicked: false,
    }
  ];

export {
    nav
}