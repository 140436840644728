import { withRouter } from "react-router-dom";
import "./App.scss";
import loadable from '@loadable/component';
import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import { Route, Switch } from "react-router";
import Nav from "./components/nav/nav.component";
import NotFound from "./pages/PageNotFound/pagenotfound.component";
import { nav } from "./components/constants";

 const HomePageWrapper = loadable(() => import(/* webpackChunkName: 'app-home' */ "./pages/homepage/homepage.component"))

 const ThirrukurralWrapper = loadable(() => import(/* webpackChunkName: 'app-thirrukurral' */ "./pages/thirukurral/thirukurral.component"))

 const ExcerciseWrapper = loadable(() => import(/* webpackChunkName: 'app-practice' */ "./pages/practice/practice.component"))

function App({ history }) {
  const { location } = history;
  const { pathname } = location;
  const isPathFound = nav.some((item) => item.link.toLowerCase() === pathname.toLowerCase());
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      {isPathFound ? (
        <>
          <Nav location={history.location} />
          <div id="content">
            <Switch>
              <Route path="/" exact component={HomePageWrapper} />
              <Route path="/kurral" exact component={ThirrukurralWrapper} />
              <Route path="/kurral/excercise" exact component={ExcerciseWrapper} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </>
      ) : (
        <div id="content" className="pageNotFound">
          <NotFound />
        </div>
      )}
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default withRouter(App);
